<template>
  <div>
    <h1 class="header">Discover vegan recipes</h1>
    <v-row dense>
      <v-col cols="10" sm="10" md="10">
          <v-text-field
            prepend-icon="fa-search" 
            label="Search by ingredient or name"
            solo
            hide-details
            v-model="queryLocal" 
            @keyup.enter="searchRecipes"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn large color="primary">
            Search
          </v-btn> 
        </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-switch v-model="sugarFree" label="Sugar Free" hide-details prepend-icon="fa-filter" class="mt-0" v-on:change="searchRecipes(true)" />
      </v-col>
      <v-col cols="4">
        <v-switch v-model="oilFree" label="Oil Free" hide-details class="mt-0" v-on:change="searchRecipes(true)" />
      </v-col>
      <v-col cols="4">
        <v-btn text :color="hasFilter ? 'primary' : ''" @click="showFilter=!showFilter">More filters</v-btn>
      </v-col>
    </v-row>

    <v-row dense v-if="hasFilter">
      <v-col cols="12">
        <h2>Advanced recipe filters</h2>
        <p>Use this filters to select recipes matching your taste or nutritional intake targets.</p>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <NutrientSelector v-on:change="selectNutrientFilter" :nutrientId="nutrientIdLocal" @loadNutrient="nutrient = $event"/>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          :items="$store.state.search.allergies"
          @change="searchRecipes"
          v-model="allergiesLocal" 
          prepend-icon="fa-allergies" 
          item-value="id"
          item-text="name"
          label="Exclude by allergy"
          multiple
          solo
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <IngredientSelector v-on:change="selectIngredientFilter" :foodIds="foodIdsLocal"/>
      </v-col>
    </v-row>

    <component
      v-bind:is="'v-fade-transition'"
      hide-on-leave
    >
      <v-row dense v-if="searching">
        <v-col v-for="n in 8" :key="n" cols="12" sm="6" md="4" lg="3">
          <v-skeleton-loader
            height="300"
            type="card-avatar, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>

      <div v-else>
        <v-alert type="info" v-if="resultCount == 0">
          No results found matching your query. Try searching for a different ingredient.
        </v-alert>
        <v-alert type="success" v-if="resultCount > 0">
          {{resultCount}} recipes have been found matching your criteria.
          <span v-if="meta && meta.has_more">
            Please add one or more filters to return more results.
          </span>
          <span v-if="nutrient">
            Recipes are sorted by their content of {{ nutrient.name }}. Value per serving of {{ nutrient.name }} is displayed
            <v-chip color="primary">per recipe</v-chip>
          </span>
        </v-alert>

        <RecipeGrid :recipes="recipes" />

        <div v-if="!resultCount && popularFood">
          <v-card-title class="headline">Popular ingredients</v-card-title>
          <IngredientGrid :items="popularFood" />
        </div>
      </div>
    </component>
    
  </div>
</template>

<script>

import { mapState } from 'vuex'
import RecipeGrid from "@/components/RecipeGrid.vue";
import IngredientGrid from "@/components/IngredientGrid.vue";
import NutrientSelector from "@/components/NutrientSelector.vue";
import IngredientSelector from "@/components/IngredientSelector.vue";
import nutrientService from "@/services/nutrientService";
import recipeService from "@/services/recipeService";
import foodService from "@/services/foodService";
import { VFadeTransition } from 'vuetify/lib'
export default {
  name: "Recipes",
  components: {
    VFadeTransition,
    RecipeGrid,
    IngredientGrid,
    NutrientSelector,
    IngredientSelector,
  },
  props: {
    msg: String
  },
  data() {
    return {
      recipes: [],
      meta: null,
      resultCount: null,
      nutrientIdLocal: null,
      popularFood: null,
      foodIdsLocal: null,
      allergiesLocal: null,
      nutrient: null,
      queryLocal: null,
      showFilter: false,
      oilFree: false,
      sugarFree: false,
      searching: false,
    };
  },
  /*computed: mapState([
    // map this.query to store.state.query
    'query'
  ]),*/
  async mounted() {
    await this.$store.dispatch('loadfromUrl');
    if (this.$store.getters.hasSearch) {
      this.queryLocal = this.$store.state.filter.query;
      this.allergiesLocal = this.$store.state.filter.allergies;
      this.foodIdsLocal = this.$store.state.filter.foodIds || [];
      this.nutrientIdLocal = this.$store.state.filter.nutrientId;
      this.sugarFree = this.$store.state.filter.sugarFree;
      this.oilFree = this.$store.state.filter.oilFree;
      this.searchRecipes(false);
    }
    else {
      this.loadPopularFood();
    }
  },
  methods: {
    loadPopularFood() {
      foodService.getPopular().then(response => {
        this.popularFood = response.data.data;
      }).catch(() => {
        this.popularFood = null;
      });
    },
    selectNutrientFilter(val) {
      console.log("Updating nutrient filter:", val);
      this.nutrientIdLocal = val;
      this.searchRecipes();
    },
    selectIngredientFilter(val) {
      this.foodIdsLocal = val;
      console.log("Updating ingredient filter:", this.foodIdsLocal);
      this.searchRecipes();
    },
    async searchRecipes(updateState = true) {
      var payload = {
        query: this.queryLocal,
        nutrientId: this.nutrientIdLocal,
        allergies: this.allergiesLocal,
        foodIds: this.foodIdsLocal,
        sugarFree: this.sugarFree,
        oilFree: this.oilFree,
      };
      if (updateState) {
        this.$store.commit('storeQuery', payload);
        await this.$store.dispatch('storeInUrl');
      }
      var data = this.$store.getters.searchPostData;
      console.log("Searching for recipes with filter: ", data);

      this.searching = true;
      try {
      const response = await recipeService.postSearch(data);
      this.recipes = response.data.data;
      this.meta = response.data.meta;
      this.resultCount = response.data.meta.count;
      }
      catch {
        this.recipes = [];
        this.resultCount = null;
      }
      this.searching = false;

    },
  },
  computed: {
    hasFilter: function() {
      return this.showFilter || this.$store.getters.hasFilter;
    }
    /*...mapState({
      //searchFilter: state => state.searchFilter,
      //allergies: state => state.allergies,
      //foodIds: state => state.filter.foodIds,
      //query: state => state.query,
      //nutrientId: state => state.filter.nutrientId,
    })*/
  },
  watch: {
    recipes() {
      console.log('Recipes updated! Refresh please', this.recipes);
      this.$forceUpdate();
    }
  }
};
</script>
